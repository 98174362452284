<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('products')"
                        :isNewButton="checkPermission('product_store')"
                        :is-filter="false"
				        @new-button-click="add"
				        @filter-div-status="datatable.filterStatus = $event">
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('products')"
                              :isNewButton="checkPermission('product_store')"
                              :is-filter="false"
				              @new-button-click="add"
				              @filter-div-status="datatable.filterStatus = $event">
				</HeaderMobile>
			</template>
			<datatable :isLoading.sync="datatable.isLoading"
			           :columns="datatable.columns"
			           :rows="datatable.rows"
			           :total="datatable.total"
			           :queryParams="datatable.queryParams"
			           :lineNumbers="false"
			           @on-page-change="onPageChange"
			           @on-sort-change="onSortChange"
			           @on-per-page-change="onPerPageChange"
			           v-show="datatable.showTable">
			</datatable>
			<CommonModal ref="modal" size="md">
				<template v-slot:CommonModalTitle>
					<div>{{ $t(id == 0 ? 'add' : 'edit') }}</div>
				</template>
				<template v-slot:CommonModalContent>
					<div class="row mb-5 align-items-center">
						<div class="col-12">
							<ValidationObserver ref="storeForm">
								<div class="row">
									<div class="col-12">
										<ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
											<b-form-group :label="$t('name')">
												<b-form-input v-model="form.name">
												</b-form-input>
												<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
												</b-form-invalid-feedback>
											</b-form-group>
										</ValidationProvider>
									</div>
									<div class="col-12">
										<ValidationProvider name="category" rules="required" v-slot="{ valid, errors }">
											<b-form-group :label="$t('category')">
												<b-form-select v-model="form.category" :options="options">
												</b-form-select>
												<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
												</b-form-invalid-feedback>
											</b-form-group>
										</ValidationProvider>
									</div>
									<div class="col-12">
										<ValidationProvider name="apply_scholarship" rules="required"
										                    v-slot="{ valid, errors }">
											<b-form-group :label="$t('apply_scholarship')">
												<true-false-selectbox :validateError="errors[0]"
												                      v-model="form.apply_scholarship" />
											</b-form-group>
										</ValidationProvider>
									</div>
									<div class="col-12">
										<ValidationProvider name="apply_cash_discount" rules="required"
										                    v-slot="{ valid, errors }">
											<b-form-group :label="$t('apply_cash_discount')">
												<true-false-selectbox v-model="form.apply_cash_discount" />
												<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
											</b-form-group>
										</ValidationProvider>
									</div>
									<div class="col-12">
										<ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
											<b-form-group :label="$t('status')">
												<status-selectbox v-model="form.status">
												</status-selectbox>
												<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
												</b-form-invalid-feedback>
											</b-form-group>
										</ValidationProvider>
									</div>

								</div>
							</ValidationObserver>
						</div>
						<div class="col-12 mt-3 d-flex">
							<b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
								{{ $t('save') | toUpperCase }}
							</b-button>
						</div>
					</div>
				</template>
			</CommonModal>
		</app-layout>
	</div>
</template>
<script>
	import AppLayout from "@/layouts/AppLayout"
	import Header from "@/layouts/AppLayout/Header"
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
	import Datatable from "@/components/datatable/Datatable";
	import qs from 'qs'
	import ProductService from "@/services/ProductService";
	import CommonModal from "@/components/elements/CommonModal";
	import {ValidationObserver, ValidationProvider} from "vee-validate";
	import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox.vue';
	import TrueFalseSelectbox from '@/components/interactive-fields/TrueFalseSelectbox.vue';

	export default {
		components: {
			AppLayout,
			Header,
			HeaderMobile,

			Datatable,
			CommonModal,
			ValidationProvider,
			ValidationObserver,
			StatusSelectbox,
			TrueFalseSelectbox
		},
		metaInfo() {
			return {
				title: this.$t('products')
			}
		},
		data() {
			return {
				id: 0,
				form: {},
				options: [

					{value: 'tuition', text: this.$t('tuition')},
					{value: 'certificate', text: this.$t('certificate')},
					{value: "services", text: this.$t('services')},
					{value: "course", text: this.$t('course')},
					{value: 'other', text: this.$t('other')}
				],
				datatable: {
					isLoading: false,
					columns: [
						{
							label: '',
							field: 'buttons',
							html: true,
							sortable: false,
							tdClass: 'p-0 text-center w-40 align-middle',
							buttons: [
								{
									text: this.$t('edit'),
									class: 'ri-edit-box-line',
                                    permission:'product_update',
									callback: (row) => {
										this.id = row.id;
										this.loadData();
									}
								},
								{
									text: this.$t('delete'),
									class: 'ri-delete-bin-2-line',
                                    permission:'product_delete',
									callback: (row) => {
										this.delete(row.id);
									}
								},
							]
						},
						{
							label: this.$t('id'),
							field: 'id',
							sortable: true,
                            hidden:false
						},

						{
							label: this.$t('name'),
							field: ('name'),
							sortable: true,
                            hidden:false
						},


					],
					rows: [],
					total: 0,
					filterStatus: true,
					showTable: false,
					queryParams: {
						filter: {},
						sort: 'id',
						page: 1,
						limit: 20
					}
				}
			}
		},
		methods: {
			filter() {
                this.datatable.queryParams.page = 1;
				this.getRows();
			},
			filterClear() {
				this.datatable.queryParams.filter = {};
				this.getRows();
			},
			onPageChange(params) {
				this.datatable.queryParams.page = params.currentPage;
				this.getRows();
			},
			onPerPageChange(params) {
				this.datatable.queryParams.limit = params.currentPerPage;
				this.datatable.queryParams.page = 1;
				this.getRows();
			},
			onSortChange(params) {
				const sortType = params[0].type == 'desc' ? '-' : '';
				this.datatable.queryParams.sort = sortType + params[0].field
				this.getRows();
			},
			getRows() {
				this.datatable.showTable = true;
				this.datatable.isLoading = true;

				const config = {
					params: {
						...this.datatable.queryParams
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				};

				return ProductService.getAll(config)
				                     .then((response) => {
					                     this.datatable.rows = response.data.data

				                     })
				                     .finally(() => {
					                     this.datatable.isLoading = false;
				                     });
			},
			loadData() {
				if (this.id > 0) {
					ProductService.get(this.id)
					              .then(response => {
						              this.$refs.storeForm.reset();
						              this.form = response.data.data;
						              this.$refs.modal.$refs.commonModal.show();
					              }).catch(error => {
						if (error.data.message) {
							this.$toast.error(this.$t('api.' + error.data.message));
						}
					});
				}
			},
			async store() {
				const isValid = await this.$refs.storeForm.validate()
				if (isValid) {
					let formData = {...this.form}
					this.setBooleans(formData);
					if (this.id == 0) {
						ProductService.store(formData, this.value)
						              .then((response) => {
							              this.$toast.success(this.$t("api." + response.data.message));
                                          this.$refs.modal.$refs.commonModal.hide();
                                          this.getRows()
						              })
						              .catch((error) => {
							              this.showErrors(error)
						              })
					}
					else {
						ProductService.update(this.id, formData)
						              .then((response) => {
							              this.$toast.success(this.$t("api." + response.data.message));
                                          this.$refs.modal.$refs.commonModal.hide();
                                          this.getRows()
						              })
						              .catch((error) => {
							              this.showErrors(error)
						              })
					}

				}
			},
			showErrors(error) {
				if (error.status == 422) {

					if (error.data.errors.name) {
						this.$refs.storeForm.errors.name.push(error.data.errors.name[0]);
					}
					if (error.data.errors.category) {
						this.$refs.storeForm.errors.category.push(error.data.errors.category[0]);
					}
					if (error.data.errors.apply_scholarship) {
						this.$refs.storeForm.errors.apply_scholarship.push(error.data.errors.apply_scholarship[0]);
					}
					if (error.data.errors.apply_cash_discount) {
						this.$refs.storeForm.errors.apply_cash_discount.push(error.data.errors.apply_cash_discount[0]);
					}
					if (error.data.errors.status) {
						this.$refs.storeForm.errors.status.push(error.data.errors.status[0]);
					}
				}
				else if (error.status == 406) {
					this.$toast.error(this.$t('api.' + error.data.message));
				}
			},
			delete(id) {
				this.deleteModal(() => {
					ProductService.deleteProduct(id)
					              .then(response => {
						              this.filter();
						              this.$toast.success(this.$t('api.' + response.data.message));
					              })
					              .catch(error => {
						              this.$toast.error(this.$t('api.' + error.data.message));
					              });
				});
			},
			add() {
				this.id = 0;
				this.$refs.storeForm.reset();
				this.form = {};
				this.$refs.modal.$refs.commonModal.show();
			}
		},
		created() {
			this.filter();
		}
	};
</script>

